import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../layouts/Contact/";

const ContactPageFr = () => {
  return (
    <Layout 
    lang="fr-CA" 
    switchLink='/en/contact-us' 
    slider={false} 
    title="d'affichage dynamique" 
    subTitle="Parlez-nous de votre projet">
      <Seo 
        title="Parlez-nous de votre projet d'affichage numérique dynamique"
        description="Laissez-nous vous montrer comment les systèmes d'Attractif  facilitent la communication avec votre clientèle et vos employés."
       />
      <Contact lang="fr-CA" />
    </Layout>
  )
}

export default ContactPageFr
